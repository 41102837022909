import React from "react"
import { Fade } from "react-reveal"

const Brand: React.FC<any> = (brand) => {
  return (
    <div className={`c-brand ${brand.isInverted ? 'c-brand--inverted' : ''}`}>
      <div className="slide-in">
        <img src={brand.fieldImg.url} alt={brand.fieldImg.alt} />
      </div>
      <div className={`${brand.nid === 4 ? 'left' : ''}`}>
        <Fade bottom>
          <h5>{brand.title}</h5>
          <a href={'//' + brand.fieldLink}>{brand.fieldLink}</a>
          <ul>
            {
              brand.fieldFbLink &&
              <li>
                <a href={'//' + brand.fieldFbLink}>
                  <img src={require('../assets/img/facebook.svg')} alt="facebook" />
                </a>
              </li>
            }
            {
              brand.fieldIgLink &&
              <li>
                <a href={'//' + brand.fieldIgLink}>
                  <img src={require('../assets/img/instagram.svg')} alt="instagram" />
                </a>
              </li>
            }
            {
              brand.fieldTwLink &&
              <li>
                <a href={'//' + brand.fieldTwLink}>
                  <img src={require('../assets/img/twitter.svg')} alt="twitter" />
                </a>
              </li>
            }
            {
              brand.fieldYtLink &&
              <li>
                <a href={'//' + brand.fieldYtLink}>
                  <img src={require('../assets/img/youtube.svg')} alt="youtube" />
                </a>
              </li>
            }
          </ul>
          <div>
            <img src={brand.fieldLogo.url} alt={brand.fieldLogo.alt} />
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Brand
