import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { graphql } from "gatsby"
import Brand from "../components/brand"
import Paragraph from "../components/paragraph"
import { useDispatch, useSelector } from "react-redux"
import { setPage } from "../redux/reducers/indexSlice"
import { RootState } from "../redux/store"

const Marchi: React.FC<any> = ({ data }) => {
    const language = useSelector((state: RootState) => state.inner.language)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPage(3))
    }, [])

    return (
        <Layout>
            <SEO title={data.drupal[language.toLowerCase()].title} />
            <Banner
                title={data.drupal[language.toLowerCase()].title}
                image={data.drupal[language.toLowerCase()].fieldImg}
            />
            <Paragraph {...data.drupal[language.toLowerCase()].fieldContenuti[0].entity} />
            {
                data.drupal.marchi.entities.map((brand, index) => {
                    if (brand && brand.nid) {
                        return <Brand {...brand} key={`brand-${brand.nid}`} isInverted={index % 2 !== 0} />
                    }
                    return null
                })
            }
        </Layout>
    )
}

export default Marchi

export const query = graphql`
    query {
        drupal {
            marchi: nodeQuery(offset: 0, limit: 1000, filter: {conditions: [{field: "type", operator: EQUAL, value: ["marchi"]}]}) {
                entities {
                  ... on Drupal_NodeMarchi {
                    fieldLink
                    fieldLogo {
                      alt
                      url
                    }
                    fieldImg {
                      alt
                      url
                    }
                    fieldFbLink
                    fieldIgLink
                    fieldTwLink
                    fieldYtLink
                    nid
                    title
                  }
                }
              }
        it: nodeById(id: "12", language: IT) {
            ... on Drupal_NodePaginaBase {
            fieldImg {
                url
                alt
            }
            fieldContenuti {
                entity {
                ... on Drupal_ParagraphUnaColonna {
                    fieldTesto
                    fieldTitolo
                }
                }
            }
            }
            title
        }
        en: nodeById(id: "12", language: EN) {
            ... on Drupal_NodePaginaBase {
            fieldImg {
                url
                alt
            }
            fieldContenuti {
                entity {
                ... on Drupal_ParagraphUnaColonna {
                    fieldTesto
                    fieldTitolo
                }
                }
            }
            }
            title
        }
        }
    }
    
`